/*
    Initial widget
    Active class: 'm-tabs__active' or customise this class for display
    Widget structure:
    
    <div class="some-class" data-widget-tabs="1">
        <button type="button" class="some-class m-tabs__active" data-tab="1"><span>Some name</span></button>
        <button type="button" class="some-class" data-tab="2"><span>Some name</span></button>
    </div>
    <div class="some-class" data-widget-pane="1">
        <div class="some-class m-tabs__active" data-pane="1">
            Some content
        </div>
        <div class="some-class" data-pane="2">
            Some content
        </div>
    </div>
 */

class Tabs {
    constructor(target) {
        this.target = target;
        this.paneWrap = document.querySelector(`[data-widget-pane="${this.target.dataset.widgetTabs}"]`);
        this.tabsElements = this.target.querySelectorAll('[data-tab]');
        this.paneElements = this.paneWrap.querySelectorAll('[data-pane]')
        this.init();
    }

    init() {
        if (!this.paneWrap) {
            return;
        }

        this.tabClickHandler();
    }

    tabClickHandler() {
        if (!this.tabsElements.length || !this.paneElements.length) {
            return;
        }
        this.tabsElements.forEach(tab => {
            tab.addEventListener('click', () => {
                [...this.tabsElements].filter(el => el.classList.contains('m-tabs__active')).map(el => el.classList.remove('m-tabs__active'));
                [...this.paneElements].filter(el => el.classList.contains('m-tabs__active')).map(el => el.classList.remove('m-tabs__active'));
                tab.classList.add('m-tabs__active');
                [...this.paneElements].filter(el => el.dataset.pane === `${tab.dataset.tab}`).map(el => el.classList.add('m-tabs__active'));
            })
        })
    }
}

export default Tabs;