import { Tabs, Switcher, ModalWindow} from './components/common'; 


const scriptsInit = () => {
    document.addEventListener('DOMContentLoaded', () => {
        // modals initializer
        document.querySelectorAll('.mw__container')?.forEach(mw => new ModalWindow(mw));
        // tabs initializer
        document.querySelectorAll('[data-widget-tabs]')?.forEach(wt => new Tabs(wt));
        // switchers initializer
        document.querySelectorAll('[data-widget-switcher]')?.forEach(sw => new Switcher(sw));
    })
}

export default scriptsInit;
