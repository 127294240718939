/*
    Initial widget
    Active class: 'm-switcher__active'
    Widget structure:

    <div class="some-class" data-widget-switcher="1">
        <button type="button" class="switcher m-switcher__active" data-switcher-item="1"><span>Some name</span></button>
        <button type="button" class="switcher" data-switcher-item="2"><span>Some name</span></button>
    </div>
 */

class Switcher {
    constructor(target) {
        this.target = target;
        this.buttons = this.target.querySelectorAll('[data-switcher-item]');
        this.init();
    }

    init() {
        this.buttonClickHandler();
    }

    buttonClickHandler() {
        this.buttons?.forEach(btn => {
            btn.addEventListener('click', () => {
                [...this.buttons]?.filter(el => el.classList.contains('m-switcher__active')).map(el => el.classList.remove('m-switcher__active'));
                btn.classList.add('m-switcher__active');
            })
        })
    }
}

export default Switcher;