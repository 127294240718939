class ModalWindow {
    constructor(modal) {
        this.modal = modal;
        this.buttonClose = this.modal.querySelector('.mw__close-img');
        this.openButtons = [...document.querySelectorAll('[data-click]')];
        this.isOpen = false;
        this.init();
    }

    init() {
        this.openButtonsHandler();
        this.buttonCloseHandler();
        this.modalCloseSelf();
        this.showPassword();
    }

    openButtonsHandler() {
        this.openButtons?.map(btn => {
            btn.addEventListener('click', () => {
                this.openModal();
                [...this.modal.querySelectorAll(`[data-target]`)]?.map(form => form.classList.add('m-hidden'));
                this.modal.querySelector(`[data-target="${btn.dataset.click}"]`)?.classList.remove('m-hidden');
            });
        })
    }

    buttonCloseHandler() {
        this.buttonClose?.addEventListener('click', () => {
            this.isOpen ? this.closeModal() : this.openModal();
        })
    }

    modalCloseSelf() {
        this.modal.addEventListener('click', ({ target }) => {
            if (target.closest('.mw__content-wrap')) {
                return;
            }
            this.closeModal();
        })
    }

    openModal() {
        this.modal.classList.add('m-open');
        this.isOpen = true;
        document.body.classList.add('m-overflow-hidden');
    }

    closeModal() {
        this.modal.classList.remove('m-open');
        this.isOpen = false;
        document.body.classList.remove('m-overflow-hidden');
        setTimeout(() => {
            [...this.modal.querySelectorAll(`[data-form]`)]?.map(form => form.classList.add('m-hidden'));
            // this.modal.querySelector('[data-target="registration"]')?.classList.remove('form__reg-hidden');
        }, 200);
    }

    showPassword() {
        [...this.modal.querySelectorAll('input[type="password"]')]?.map(input => {
            const showIcon = input.closest('.form__input-wrap')?.querySelector('.m-icon--eye-slash');
            let showPass = false;
            showIcon?.addEventListener('click', () => {
                if (showPass) {
                    input.type = 'password';
                    showIcon.classList.add('m-icon--eye-slash');
                    showIcon.classList.remove('m-icon--eye');
                    showPass = !showPass;
                } else {
                    input.type = 'text';
                    showIcon.classList.add('m-icon--eye');
                    showIcon.classList.remove('m-icon--eye-slash');
                    showPass = !showPass;
                }
            })
        })
    }
}

export default ModalWindow;
